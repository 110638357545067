<template>
  <div class="cash-operation-wrapper page">
    <alert
      v-if="!getOpenedWorkingShift"
      type="error"
      title="Внимание"
      description="Для совершения операций по кассе, необходимо открыть смену!"
    />

    <alert
      v-else
      type="warning"
      title="Внимание"
      description="Отображаются только кассовые операции с наличными!"
    />

    <el-card
      v-if="getLastBalance"
      class="box-card box-control"
    >
      <el-row :gutter="20">
        <el-col :span="6">
          <el-statistic
            group-separator=" "
            decimal-separator=""
            :precision="2"
            title="Остаток наличных в кассе"
          >
            <template slot="suffix">
              <span>р.</span>
            </template>
            <template slot="formatter">
              <span>{{ getPriceToFormat(getLastBalance) }}</span>
            </template>
          </el-statistic>
        </el-col>
      </el-row>
    </el-card>

    <data-table
      text-button-add="Операция по кассе"
      :data="renderData"
      :hide-box-control="!getOpenedWorkingShift"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import DataTable from '@/components/dataTable'
import Alert from '@/components/alert'
import moment from 'moment'
import { getPriceToFormat } from '@/helper'

// TODO: Запросы ебут бэк и БД, перепроверить
export default {
  name: 'CashOperation',
  components: {
    DataTable,
    Alert
  },
  computed: {
    ...mapGetters('cashOperation', ['getData', 'getLastBalance']),
    ...mapGetters('settings', ['getDefaultValues']),
    ...mapGetters('workingShift', ['getOpenedWorkingShift']),
    renderData () {
      return this.getData.map(item => {
        return {
          ...item,
          operationType: this.getDefaultValues?.operationType?.filter(({ code }) => code === item.operationType)[0]?.name,
          createdAt: moment(item.createdAt).format('dd - DD.MM.YYYY HH:mm')
        }
      })
    }
  },
  async created () {
    await this.loadData()
  },
  methods: {
    ...mapActions('user', { loadDataUser: 'loadData' }),
    ...mapActions('settings', { loadDataSettings: 'loadData' }),
    ...mapActions('cashOperation', { loadDataCashOperation: 'loadData', loadLastBalance: 'loadLastBalance' }),
    ...mapActions('workingShift', { loadDataWorkingShift: 'loadData' }),
    getPriceToFormat,
    async loadData () {
      // TODO: Подумать над оптимизацией загрузки данных НА ВСЕХ стр.
      await this.$bus.emit('start-loader')
      await this.loadDataUser()
      await this.loadDataSettings()
      await this.loadLastBalance()
      // await this.loadDataCashOperation()
      await this.loadDataWorkingShift()
      await this.$nextTick()
      await this.$bus.emit('stop-loader')
    },
  }
}
</script>

<style lang="scss" src="./style.scss" />